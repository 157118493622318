import React from 'react';
import {Link} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import {getLng} from "../helpers";


const Cabecalho = () => {
    const {  i18n } = useTranslation();

    const activeLng = getLng(i18n)



    const changeLanguage = (event) => {
        event.preventDefault();
        setTimeout(() => {
          i18n.changeLanguage(activeLng === 'en' ? 'pt' : 'en');
          window.location="/"
        }, 1000)
    };

    return (

      <header className="l-header l-header_overlay" >

      <div className="l-navbar l-navbar_expand l-navbar_t-semi-trans js-navbar-sticky">
          <div className="container">
              <nav className="menuzord js-primary-navigation" role="navigation" aria-label="Primary Navigation">

                  <a href="/" className="logo-brand">
                      <img src="assets/img/LogoJive.png" alt="Logo Jive" />
                  </a>

                  <ul className="menuzord-menu menuzord-right c-nav_s-standard scrollable">
                      <li><a  className='main-menu-option' onClick={(e) => {e.preventDefault()}} href="/"><Trans>Sobre</Trans></a>
                          <ul className="dropdown">
                              {/* <li><a href="/a-empresa"><Trans>A empresa</Trans></a>
                              </li> */}
                              {/* <li><a href="/equipe-de-gestao"><Trans>MANAGING PARTNERS</Trans></a>
                              </li> */}
                              {/* <li><a href="/estrategias"><Trans>Estratégias</Trans></a></li> */}
                              {/* <li><a href="/documentos">Documentos da Gestora</a>
                              </li> */}
                               <li><a href="/compliance">Compliance</a>
                              </li>
                          </ul>
                      </li>
                      <li><a  className='main-menu-option' onClick={(e) => {e.preventDefault()}} href="/"><Trans>Fundos</Trans></a>
                          <ul className="dropdown">
                              <li><a href="/jive-distressed-fim-cp-ie">JIVE DISTRESSED FIM CP</a>
                              </li>
                              <li><a href="/jive-distressed-fim-ii-cp-ie">Jive Distressed FIM II CP</a>
                              </li>
                              <li><a href="/jive-distressed-fim-iii-cp">Jive Distressed FIM III CP</a>
                              </li>
                              <li><a href="https://hs.jiveinvestments.com/jive-distressed-special-sits-iv-advisory-profissional">JIVE DISTRESSED & SPECIAL SITS IV ADVISORY (PROFISSIONAL)</a>
                              </li>
                              <li><a href="https://hs.jiveinvestments.com/jive-distressed-special-sits-iv-advisory-qualificado-iii">JIVE DISTRESSED & SPECIAL SITS IV ADVISORY (QUALIFICADO) III</a>
                              </li>
                               <li><a href="https://hs.jiveinvestments.com/bossanova">Jive BossaNova</a>
                              </li>
                              <li><a href="https://hs.jivemaua.com.br/bossanovafiinfra">JIVEMAUÁ BOSSANOVA FIC FI-INFRA (JMBI11)</a>
                              </li>
                              <li><a href="https://hs.jiveinvestments.com/jive-soul-prev">JIVE SOUL PREV</a>
                              </li>
                              <li><a href="https://www.mauacapital.com/">Fundos Imobiliários (Mauá)
                              </a>
                              </li>
                              

                          </ul>
                      </li>
                      <li><a  className='main-menu-option' onClick={(e) => {e.preventDefault()}} href="/">News</a>
                          <ul className="dropdown">
                              <li><a href="/jive-na-midia"><Trans>Jive na mídia</Trans></a>
                              </li>
                              {/* <li><a href="/noticias-do-mercado">Notícias do Mercado</a>
                              </li> */}
                              
                          </ul>
                      </li>
                      <li><a href="/contato"><Trans>Contato</Trans></a>
                      </li>
                      <li><a target={'_blank'} href="https://secure.investorvision.io/lp/"><span className="btn btn-medium btn-rounded btn-light-solid investor-login">INVESTOR LOGIN</span></a></li>
                      <li className="social-header">
                          {/* <a href="https://www.youtube.com/channel/UCcjdd17OoFhXkN4-Vqpvh_g" >
                              <i className="fa fa-youtube"></i>
                          </a>
                          <a href="https://www.linkedin.com/company/jive-asset-management/">
                              <i className="fa fa-linkedin"></i>
                          </a> */}
                          <a onClick={(e) => changeLanguage(e)} href="/">
                              <img src={`assets/img/${activeLng !== 'en' ? 'en' : 'pt-br'}.png`} alt='en' />
                          </a>
                      </li>
                  </ul>

              </nav>
          </div>
      </div>

      </header>

    );
}
export default Cabecalho;
